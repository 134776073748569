/* You can add global styles to this file, and also import other style files */

$primary: rgb(42, 59, 111);

@import "~bootswatch/dist/lumen/variables";
@import "~bootstrap/scss/bootstrap";
@import "~bootswatch/dist/lumen/bootswatch";
@import "~@ng-select/ng-select/themes/default.theme.css";

html {
  scroll-behavior: smooth;
}

@font-face {
  font-family: montserrat;
  src: url(assets/fonts/montserrat.woff) format("woff");
}

@font-face {
  font-family: khula;
  src: url(assets/fonts/khula.woff) format("woff");
}

@font-face {
  font-family: lora;
  src: url(assets/fonts/Lora-Regular.ttf) format("truetype");
}

@font-face {
  font-family: athelas;
  src: url(assets/fonts/Athelas-Regular.ttf) format("truetype");
}

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";

body,
p,
li {
  line-height: 1.7;
  -webkit-font-smoothing: antialiased;
}

.btn-container {
  height: 2em;
}
